import { createApp } from 'vue'
import VueGridLayout from 'vue-grid-layout'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue-pro'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'

import CKEditor from '@ckeditor/ckeditor5-vue'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(VueGridLayout)
app.use(CoreuiVue)
app.use(CKEditor)
app.provide('icons', icons)
app.component('CIcon', CIcon)

app.mount('#app')
