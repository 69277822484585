<template>
  <CSidebar
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <div class="p-2">
        <CLink href="/"
          ><span class="h4 text-warning smartCC">smartCC</span></CLink
        >
        <span class="small">&nbsp; Service Desk</span>
      </div>
    </CSidebarBrand>
    <AppSidebarNav />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'

export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
    }
  },
}
</script>

<style>
.sidebar-brand a {
  text-decoration: none;
}
.smartCC {
  font-family: Maven Pro;
  font-size: 1.8rem;
  text-shadow: 1px 0px 3px #ffd341;
}
</style>
