export default [
  {
    component: 'CNavTitle',
    name: 'Business Cases',
  },
  {
    component: 'CNavItem',
    name: 'Intent Classifier',
    to: '/intent',
    icon: 'cil-layers',
  },

  {
    component: 'CNavTitle',
    name: 'Tools',
  },

  {
    component: 'CNavItem',
    name: 'FAQ',
    to: '/faq',
    icon: 'cil-list-numbered',
  },
  {
    component: 'CNavItem',
    name: 'Search',
    to: '/search',
    icon: 'cil-search',
  },
]
