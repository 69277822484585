import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'smartCC',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: 'infocenter',
        name: 'InfoCenter',
        component: () => import('@/views/tools/InfoCenter.vue'),
      },
      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/tools/Semanteer.vue'),
      },
      {
        path: 'intent',
        name: 'Intent Classifier',
        component: () => import('@/views/tools/IntentRecognition.vue'),
      },
      {
        path: 'faq',
        name: 'FAQ',
        component: () => import('@/views/tools/FAQ.vue'),
      },
    ],
  },
  {
    path: '/cases',
    redirect: '/cases/start',
    name: 'Business Cases',
    component: DefaultLayout,
    children: [
      {
        path: 'browse',
        name: 'Browse',
        component: () => import('@/views/cases/BCSearch.vue'),
      },
      {
        path: 'start',
        name: 'Start',
        component: () => import('@/views/cases/BCList.vue'),
      },
      {
        path: 'process',
        name: 'Process',
        component: () => import('@/views/cases/BCHandling.vue'),
      },
    ],
  },
  {
    path: '/chat',
    redirect: '/chat/inbox',
    name: 'Chat',
    component: DefaultLayout,
    children: [
      {
        path: 'inbox',
        name: 'Requests',
        component: () => import('@/views/chat/ChatRequests.vue'),
      },
      {
        path: ':id',
        meta: {
          label: 'Anliegen',
        },
        name: 'Chat',
        component: () => import('@/views/chat/ChatMessage.vue'),
      },
    ],
  },
  {
    path: '/email',
    redirect: '/email/inbox',
    name: 'Email',
    component: DefaultLayout,
    children: [
      {
        path: 'compose',
        name: 'Compose',
        component: () => import('@/views/email/Compose.vue'),
      },
      {
        path: 'inbox',
        name: 'Inbox',
        component: () => import('@/views/email/Inbox.vue'),
      },
      {
        path: ':id',
        name: 'Message',
        component: () => import('@/views/email/Message.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
