<template>
  <CFooter>
    <div class="ms-auto">
      <a
        href="https://www.contexity.ch/products/smartCC"
        target="_blank"
        class="smartCCLink"
        >smartCC</a
      >
      <span class="ms-1">&copy; {{ new Date().getFullYear() }} CONTEXITY</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
<style>
.smartCCLink {
  text-decoration: none;
}
</style>
