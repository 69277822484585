import { createStore } from 'vuex'
import axios from 'axios'

var Stomp = require('@stomp/stompjs')
var SockJS = require('sockjs-client')

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    theme: 'default',

    cui: 0,
    sidebarShow: 'responsive',
    sidebarMinimize: false,
    asideShow: false,
    darkMode: false,
    user1: 'Kunde',
    user2: 'Agent',

    authtoken: '',
    socket: null,
    stompClient: null,

    businessCasesUngrouped: '',
    chatDialogs: '',
    emailDialogs: '',

    dialogId: '',
    dialogState: '',

    followupEmail: '',

    faqs: '',
    faqSearchValue: '',
    intents: '',
    intentsSearchValue: '',
    semanteer: '',
    semanteerSearchValue: '',

    error: '',
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleTheme(state, payload) {
      state.theme = payload.value
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },

    // smartCC mutations
    updateUser1(state, user) {
      state.user1 = user
    },
    updateUser2(state, user) {
      state.user2 = user
    },
    setAuthToken(state, data) {
      state.authtoken = data
      localStorage.setItem('authtoken', state.authtoken)
    },
    removeAuthToken(state) {
      state.authtoken = ''
      localStorage.removeItem('authtoken')
    },
    updateDialogState(state, dialogState) {
      state.dialogState = dialogState
    },
    updateChatDialogs(state, dialogs) {
      state.chatDialogs = dialogs
    },
    updateEmailDialogs(state, dialogs) {
      state.emailDialogs = dialogs
    },
    updateFollowupEmail(state, followupEmail) {
      state.followupEmail = followupEmail
    },

    updateDialogId(state, dialogId) {
      state.dialogId = dialogId
    },

    updateBusinessCasesUngrouped(state, businessCasesUngrouped) {
      state.businessCasesUngrouped = businessCasesUngrouped
    },
    updateFAQSearchValue(state, value) {
      state.faqSearchValue = value
    },
    updateFAQs(state, faqs) {
      state.faqs = faqs
    },
    updateIntentsSearchValue(state, value) {
      state.intentsSearchValue = value
    },
    updateIntents(state, intents) {
      state.intents = intents
    },
    updateSemanteerSearchValue(state, value) {
      state.semanteerSearchValue = value
    },
    updateSemanteer(state, response) {
      state.semanteer = response
    },
    updateError(state, error) {
      state.error = error
    },
  },
  actions: {
    async getIntents({ commit, state }, payload) {
      state.error = ''

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_RLBPOC_IC_URL +
              '/classify?text=' +
              payload.text,
          )
          .then(
            (response) => {
              commit('updateIntents', response.data.labels)
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async getFAQs({ commit, state }, payload) {
      state.error = ''

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_RLBPOC_QA_URL + '/reply?text=' + payload.text,
          )
          .then(
            (response) => {
              console.log(response.data.faqs)
              commit('updateFAQs', response.data.faqs)
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async searchSemanteer({ commit, state }, payload) {
      state.error = ''

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_RLBPOC_SEMANTEER_URL +
              '/sl/search?q=' +
              payload.text,
          )
          .then(
            (response) => {
              commit('updateSemanteer', response.data)
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async getChatDialogs({ commit, state }, payload) {
      state.error = ''
      //commit('updateChatDialogs', '');

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/channel/CHAT?activeOnly=' +
              payload.active,
            authHeader(state),
          )
          .then(
            (response) => {
              commit('updateChatDialogs', response.data)
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async getEmailDialogs({ commit, state }) {
      state.error = ''
      //commit('updateEmailDialogs', '');

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/channel/EMAIL?activeOnly=true',
            authHeader(state),
          )
          .then(
            (response) => {
              commit('updateEmailDialogs', response.data)
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async getFollowupEmail({ commit, state }) {
      state.error = ''
      commit('updateFollowupEmail', '')

      if (!state.dialogId) return

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/' +
              state.dialogId +
              '/followupEmail',
            authHeader(state),
          )
          .then(
            (response) => {
              commit('updateFollowupEmail', response.data)

              resolve(response.data)
            },
            (error) => {
              commit('updateError', error)
              reject()
            },
          )
      })
    },
    async endDialog({ commit, state }) {
      state.error = ''

      if (!state.dialogId) return

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/' +
              state.dialogId +
              '/end',
            authHeader(state),
          )
          .then(
            (response) => {
              resolve(response.data)
            },
            (error) => {
              commit('updateError', error)
              reject()
            },
          )
      })
    },
    async getDialogState({ commit, state }) {
      state.error = ''
      //commit('updateDialogState', '');

      if (!state.dialogId) return

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/' +
              state.dialogId +
              '/state/v2',
            authHeader(state),
          )
          .then(
            (response) => {
              commit('updateDialogState', response.data)
              //console.log(response.data);

              resolve(response.data)
            },
            (error) => {
              commit('updateError', error)
              reject()
            },
          )
      })
    },
    async addUtterance({ state }, payload) {
      state.error = ''

      if (!state.dialogId) return

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/' +
              state.dialogId +
              '/utterances',
            {
              personName: 'Agent',
              text: payload.text,
            },
            authHeader(state),
          )
          .then(
            (response) => {
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async sendEmail({ state }, payload) {
      state.error = ''

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_BACKEND_API_URL + '/dm/dialogues/newEmail',
            payload,
            authHeader(state),
          )
          .then(
            (response) => {
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async saveSlot({ state }, payload) {
      // TODO
      state.error = ''

      if (!state.dialogId) return

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/' +
              state.dialogId +
              '/state/slots',
            {
              slotId: payload.id,
              value: payload.value,
            },
            authHeader(state),
          )
          .then(
            (response) => {
              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
    async getBusinessCasesUngrouped({ commit, state }) {
      state.error = ''
      commit('updateBusinessCasesUngrouped', '')

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_BACKEND_API_URL + '/dm/businesscases/ungrouped',
            authHeader(state),
          )
          .then(
            (response) => {
              commit('updateBusinessCasesUngrouped', response.data)
              resolve(response.data)
            },
            (error) => {
              commit('updateError', error)
              reject()
            },
          )
      })
    },
    async setBusinessCaseStatusInDialogue({ commit, state }, payload) {
      state.error = ''

      if (!state.dialogId) return

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_BACKEND_API_URL +
              '/dm/dialogues/' +
              state.dialogId +
              '/businesscases',
            {
              businessCaseId: payload.businessCaseId,
              status: payload.status,
            },
            authHeader(state),
          )
          .then(
            (response) => {
              //commit('updateBusinessCasesOfDialog', response.data);
              resolve(response.data)
            },
            (error) => {
              commit('updateError', error)
              reject()
            },
          )
      })
    },

    async authenticate({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_BACKEND_API_URL + '/authenticate', {
            password: 'admin',
            rememberMe: true,
            username: 'admin',
          })
          .then(
            (response) => {
              commit('setAuthToken', response.data.id_token)

              dispatch('getChatDialogs', { active: true })
              dispatch('getEmailDialogs')
              dispatch('getBusinessCasesUngrouped')

              state.socket = new SockJS(
                process.env.VUE_APP_BACKEND_WEBSOCKET_URL +
                  '/tracker?access_token=' +
                  getAuthToken(state, dispatch),
              )
              state.stompClient = Stomp.Stomp.over(state.socket)
              state.stompClient.connect(
                {},
                () => {
                  state.stompClient.subscribe('/updates/dialogues', (tick) => {
                    dispatch('getChatDialogs', { active: true })
                    dispatch('getEmailDialogs')

                    var obj = JSON.parse(tick.body)

                    if (
                      state.dialogId &&
                      obj &&
                      obj.dialogueId == state.dialogId
                    ) {
                      dispatch('getDialogState')
                    }
                  })
                },
                (error) => {
                  console.log(error)
                },
              )

              resolve(response.data)
            },
            (error) => {
              state.error = error
              reject()
            },
          )
      })
    },
  },
  modules: {},
})

function authHeader(state) {
  if (getAuthToken(state))
    return {
      headers: {
        Authorization: 'Bearer ' + getAuthToken(state),
      },
    }
  else {
    return {}
  }
}

function getAuthToken(state, dispatch) {
  if (!state.authtoken) state.authtoken = localStorage.getItem('authtoken')

  if (!state.authtoken) dispatch('authenticate')

  return state.authtoken
}
